export const resolveColor = (color?: string, shade?: number) => {
  if (color === 'primary') return variableText(color, shade)
  if (color === 'secondary') return variableText(color, shade)
  return color
}
export const resolveTextColor = (color?: string, shade?: number) => {
  if (color === 'primary') return `var(--avv-primary-text--color)`
  if (color === 'secondary') return `var(--avv-secondary-text--color)`
  return color
}

const shadeString = (shade?: number) => shade ? shade : ''

const variableText = (color: string, shade?: number) => `var(--avv-${color}-${shade ? '' : '-color'}${shadeString(shade)})`
