<template>
  <svg
    :style="{ width: props.size, height: props.size, color }"
    viewBox="25 25 50 50"
    :class="classList"
  >
    <circle
      class="path"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      stroke="currentColor"
      :stroke-width="props.thickness"
      stroke-miterlimit="10"
    ></circle>
  </svg>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { resolveColor } from './utils'

const props = withDefaults(
  defineProps<{
    size?: string
    color?: string
    thickness?: number
  }>(),
  {
    thickness: 5,
    size: '1em',
    color: 'primary'
  }
)

const classList = computed(() => {
  return {
    'a-spinner a-spinner-mat': true
  }
})

const color = computed(() => {
  return resolveColor(props.color)
})
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({name: "Spinner"})
</script>
<style lang="scss">
.a-spinner {
  vertical-align: middle;
}

.a-spinner-mat {
  animation: a-spin 2s linear infinite;
  transform-origin: center center;
  .path {
    stroke-dasharray: 1, 200 #{'/* rtl:ignore */'};
    stroke-dashoffset: 0 #{'/* rtl:ignore */'};
    animation: a-mat-dash 1.5s ease-in-out infinite;
  }
}

@keyframes a-spin {
  0% {
    transform: rotate3d(0, 0, 1, 0deg) #{'/* rtl:ignore */'};
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg) #{'/* rtl:ignore */'};
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg) #{'/* rtl:ignore */'};
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg) #{'/* rtl:ignore */'};
  }
  100% {
    transform: rotate3d(0, 0, 1, 359deg) #{'/* rtl:ignore */'};
  }
}

@keyframes a-mat-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
} ;
</style>
